import React from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { reportsExport } from "../../actions/reports";

const useStyles = makeStyles((theme) => ({
  buttonDefault: {
    minHeight: "auto",
    width: "15%",
    height: 35,
    border: "1px solid " + theme.palette.secondary.main,
    borderRadius: "5px",
    textTransform: "none",
    padding: 0,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    ".mobile &": {
      width: "50%",
      maxWidth: "50%",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
    },
  },
  buttonAdmin: {
    position: 'absolute',
    top: 55,
    right: 40,
    minHeight: "auto",
    width: "10%",
    height: 35,
    borderRadius: "5px",
    padding: 0,
    color: "rgba(0, 0, 0, 0.54)",
    backgroundColor: "#fff",
    cursor: "pointer",
    zIndex: 999,
    ".mobile &": {
      width: "50%",
      maxWidth: "50%",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
    }
  },
}));

function ExportButton({ exportPath, variant = "default", isMobileDevice }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    if (exportPath) dispatch(reportsExport(exportPath));
  };

  const buttonClass = variant === "adminBtn" ? classes.buttonAdmin : classes.buttonDefault;

  return (
    <Button
      className={buttonClass}
      onClick={handleClick}
      style={
        variant === "adminBtn" && isMobileDevice
          ?
          {
            position: 'relative',
            top: 0,
            right: 0,
            alignSelf: 'flex-end',
            border: '1px solid rgb(86, 130, 163)',
            width: '25%',
            marginBottom: 5
          } : undefined
      }
    >
      Экспорт
    </Button>
  );
}

export default ExportButton;
