import React from "react";
import { companiesInit } from "../../../actions/companies";
import { getRows } from "../../helpers/helpers";
import { connect } from "react-redux";
import Crud from "@ksbteam/core/components/Crud";
import ExportButton from "../../ExportButton/ExportButton";

function Companies({ companiesConfig, init, inited, restClient, isAdmin, isMobileDevice }) {
  React.useEffect(() => {
    if (isAdmin) {
      init();
    }
  }, [isAdmin]);

  return (
    inited &&
    companiesConfig &&
    Object.keys(companiesConfig)?.length !== 0 && (
      <div
        style={
          isMobileDevice ?
            { position: 'relative', display: "flex", flexDirection: 'column' } : { position: 'relative' }
        }
      >
        <ExportButton variant="adminBtn" isMobileDevice={isMobileDevice} />
        <Crud
          restClient={restClient}
          config={companiesConfig}
          dataGridProps={{ filters: true, getRows: getRows }}
        />
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    inited: state.companies.inited,
    companiesConfig: state.companies.companiesConfig,
    restClient: state.page.elkUser.restClient,
    isMobileDevice: state.page.isMobileDevice,
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(companiesInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
