import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Chip } from '@material-ui/core';

const styles = theme => ({
    card: {
        minWidth: 275,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    author: {
        fontSize: 16,
        marginBottom: theme.spacing()
    },
    pos: {
        marginBottom: 12,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '15px'
    },
    lastInspection: {
       background: 'rgba(255, 239, 241, 1)'
    },
    role: {
        background: 'rgba(22, 14, 240, 0.71)', 
        color: 'white'
    },
    tag: {
        background: 'rgb(0, 176, 80)', 
        color: 'white',
        textTransform: 'capitalize'
    },
    tagInspection: {
        background: 'rgb(255, 0, 0)'
    }
});

class Comments extends React.Component {
    render() {
        let {classes, comments} = this.props;
        const lastInspectionID =  comments.find((i) => i.tag === 'доработка')?.obj_id
       
        return (
            <React.Fragment>
                {comments.reverse().map(comment =>
                    <Card
                        id={`ticket-comment-${comment['obj_id']}`}
                        className={classes.card + ' ticket-comment'}
                        key={comment['obj_id']}
                    >
                        <CardContent className={`${lastInspectionID === comment.obj_id && classes.lastInspection}`}>
                            <Box className={classes.title}>
                            <Typography className={classes.date} color="textSecondary" gutterBottom>
                                {comment.created}
                            </Typography>
                            {comment?.author && <Chip label={comment?.author} size='small' className={classes.role}/>}
                            {comment?.tag && <Chip label={comment?.tag} size='small' className={`${classes.tag} ${comment?.tag === 'доработка' ? classes.tagInspection: ''}`}/>}
                            </Box>
                            <Typography className={classes.author} variant="h5" component="h5">
                                {comment.author}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {comment.comment}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        comments: state.ticket.comments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Comments));
